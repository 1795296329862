<template>
  <div class="content">
    <PageHeader :title="$t('invoices.header')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <button
                v-if="this.$store.state.account.type == 'prepaid'"
                v-modal="{ target: 'new-invoice-modal' }"
                class="btn btn-success m-width-50 btn-new"
              >
                <i class="icon dripicons-plus color-white"></i>{{$t('invoices.lbl-new-invoice')}}
              </button><!-- Nova Fatura -->
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12">
              <div class="form-row">
                <div class="col-lg-6 col-md-6">
                  <template>
                    <div>
                      <date-range-picker
                        class="form-control"
                        value
                        :options="options"
                        @change="changeDate"
                      />
                    </div>
                  </template>
                </div>
                <div class="col-lg-4 col-md-4">
                  <select class="form-control" v-model="form.status">
                    <option value="">Status</option>
                    <option value="pending">{{$t('invoices.select-status.lbl-pending')}}</option><!-- Pendente -->
                    <option value="paid">{{$t('invoices.select-status.lbl-paid')}}</option><!-- Paga -->
                    <option value="failed">{{$t('invoices.select-status.lbl-fail')}}</option><!-- Falha -->
                  </select>
                </div>
                <div class="col-lg-2 col-md-2">
                  <div class="form-group">
                    <div class="input-group">
                      <div class>
                        <button class="btn btn-danger btn-sm" @click="fetch(1)">
                          {{$t('generic-str.filter')}}
                        </button><!-- Filtrar -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <div
              v-if="invoices.length == 0 && fetched"
              class="text-center table-placeholder"
            >
              <br />
              <br />
              <i class="fas fa-file-invoice font-size-80 no-item-icon"></i>
              <h5 class="card-title m-t-20">{{$t('invoices.none')}}</h5><!-- Nenhuma fatura encontrada -->
            </div>
            <table v-if="invoices.length > 0 && fetched" class="table">
              <thead class="bg-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">{{$t('invoices.data-infos.lbl-dt-paid')}}</th><!-- Data do pagamento -->
                  <th scope="col">{{$t('invoices.data-infos.lbl-value')}}</th><!-- Valor -->
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="cursor-default"
                  v-for="(invoice, i) in invoices"
                  :key="invoice.id"
                >
                  <td data-label="ID">
                    <div class="max-200" v-tooltip.top="`${invoice.id}`">
                      {{ invoice.id }}
                    </div>
                  </td>
                  <td data-label="Data do Pagamento">{{ invoice.paid_at }}</td>
                  <td data-label="Valor">
                    {{ invoice.total | currency }}
                  </td>
                  <td data-label="Status">
                    <span
                      class="badge badge-pill badge-success"
                      v-if="invoice.status == 'paid'"
                      >{{$t('invoices.select-status.lbl-paid')}}</span
                    ><!-- Paga -->
                    <span
                      class="badge badge-pill badge-warning"
                      v-if="invoice.status == 'pending'"
                      >{{$t('invoices.select-status.lbl-pending')}}</span
                    ><!-- Pendente -->
                    <span
                      class="badge badge-pill badge-danger"
                      v-if="invoice.status == 'fail'"
                      >{{$t('invoices.select-status.lbl-fail')}}</span
                    ><!-- Falha -->
                  </td>
                  <td data-label>
                    <div class="dropdown">
                      <a
                        role="button"
                        class="dropdown-action"
                        :to="'#menu-dropdown-' + i"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="icon dripicons-gear text-success"></i>
                      </a>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        :id="'menu-dropdown-' + i"
                        x-placement="bottom-end"
                        style
                      >
                        <a
                          class="dropdown-item"
                          :href="invoice.url"
                          target="_blank"
                          >{{$t('generic-str.download')}}</a
                        ><!-- Baixar -->
                        <!--<a class="dropdown-item" @click="remove(invoice.id)"
                          >{{$t('generic-str.remove')}}</a
                        >-->
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="!fetched"
              class="qt-block-ui relative"
              style="padding: 120px"
            />
            <br />
            <pagination :totalPages="pages" v-model="currentPage" @change="fetch" />
          </div>
        </div>
      </div>
    </div>
    <new-invoice-modal
      @sent="fetch(form.page)"
      id="new-invoice-modal"
    ></new-invoice-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import InvoiceService from '@/services/invoices.service';
import PageHeader from '@/components/PageHeader.vue';
import NewInvoiceModal from '@/components/invoices/NewInvoiceModal.vue';
import Pagination from '@/components/PaginationComponent.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    NewInvoiceModal,
    Pagination,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      invoices: [],
      form: {
        begin_date: '',
        end_date: '',
        status: '',
        page: 1,
      },
      pages: 1,
      currentPage: 1,
    };
  },
  created() {
    this.changeDate();
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.fetched = false;
      this.form.page = page;
      this.currentPage = page;
      InvoiceService.getInvoices(this.form).then(
        (response) => {
          this.fetched = true;
          this.invoices = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.form.begin_date = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.form.end_date = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.form.begin_date = moment().format('YYYY-MM-DD 00:00:00');
        this.form.end_date = moment().format('YYYY-MM-DD 23:59:59');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
